module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'VVOOD • Tourneurs sur bois', // Navigation and Site Title
  siteTitleAlt: 'VVOOD Tournage sur bois', // Alternative Site title for SEO
  siteUrl: 'https://vvood.netlify.com', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteImage: '/site-image.png', // Used for SEO and manifest
  siteDescription: 'VVOOD • Tourneurs sur bois amateurs mais passionnés',
  author: 'VVOOD', // Author for schemaORGJSONLD

  siteName: '•W•',
  siteEmail: 'vvooders@gmail.com',

  siteFBAppID: '2174448589483538', // Facebook App ID - Optional
  userTwitter: '@vvood', // Twitter Username
  ogSiteName: 'vvood', // Facebook Site Name
  ogLanguage: 'fr_FR', // Facebook Language

  // Manifest and Progress color
  themeColor: '#FFFFFF',
  backgroundColor: '#807061',

  // Snipcart
  shopEnabled: false,
}
