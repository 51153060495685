import React from 'react'

import config from '../../config/website'

const Footer = () => 
  <footer className="tc pv4 bt b--black-10">
    <a
      href={`mailto:${config.siteEmail}`}
      className="link f5 tracked f4-ns fw3 dim vvood lh-solid"
    >
      {config.siteEmail}
    </a>
  </footer>


export default Footer
