import { array, node, oneOfType } from 'prop-types'
import React from 'react'

import Footer from '../components/Footer'
import Seo from '../components/Seo'
import Transition from '../components/Transition'

const Layout = ({ children, location }) => (
  <>
    <Seo />
    <div className="sans-serif bg-vvood vvood">
      <Transition location={location}>{children}</Transition>
      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: oneOfType([array, node]).isRequired,
}

export default Layout
